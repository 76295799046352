<template>
  <form
    @submit.prevent="onSumbit"
    class="flex flex-col lg:flex-row items-start lg:items-center gap-x-6 gap-y-4 w-full"
  >
    <div
      class="grow overflow-hidden w-full rounded-lg border transition-all border-gray-200 focus-within:border-los-purple-dark"
    >
      <input
        v-model="email"
        class="w-full px-5 py-2 outline-none rounded-lg bg-white"
        placeholder="Emailadres"
        alt="Vul je email adres hier in om op de hoogte te blijven van interessant aanbiedingen en de laatste nieuwtjes."
        @focus="onFocusInput"
      />
    </div>
    <button
      type="submit"
      class="px-10 py-2 rounded-full text-white font-semibold transition-all flex items-center"
      :class="{
        'bg-green-600': emailResponse == 'success',
        'bg-red-600': emailResponse == 'failed',
        [buttonBgColor]: !emailResponse,
        'opacity-50 cursor-not-allowed': emailResponse || email.length < 3,
      }"
    >
      Verzenden
      <FontAwesome
        v-if="emailResponse == 'success'"
        class="ml-2"
        icon="check"
      />
      <FontAwesome v-if="emailResponse == 'failed'" class="ml-2" icon="times" />
    </button>
  </form>
</template>

<script setup>
const toastsStore = useToastsStore();

const props = defineProps({
  color: {
    type: String,
    default: 'purple-darkest',
  },
});

const email = ref('');
const emailResponse = ref(null);

const buttonBgColor = computed(() => {
  return `bg-los-${props.color}`;
});

const onSumbit = async () => {
  if (email.value < 3 || emailResponse.value) {
    return;
  }

  try {
    const { success } = await $fetch(`/newsletter`, {
      method: 'POST',
      body: { email: email.value },
    });
    if (success) {
      emailResponse.value = 'success';
      toastsStore.setSuccessToast({
        title: 'Je bent succesvol aangemeld!',
      });
    } else {
      emailResponse.value = 'failed';
      toastsStore.setFailedToast({
        title: 'Er is iets fout gegaan met het aanmelden.',
        text: 'Gebeurd dit vaker? Neem dan contact met ons op.',
      });
    }
  } catch (err) {
    console.error(err);
    emailResponse.value = 'failed';
    toastsStore.setFailedToast({
      title: 'Er is iets fout gegaan met het aanmelden.',
      text: 'Gebeurd dit vaker? Neem dan contact met ons op.',
    });
  }
};

const onFocusInput = () => {
  if (emailResponse.value) {
    if (emailResponse.value === 'success') {
      email.value = '';
    }
    emailResponse.value = null;
  }
};
</script>

<style lang="scss" scoped></style>
